<template>
  <div id="password-recovery">
    <div class="d-flex justify-content-center m-5">
      <b-img-lazy
        :src="require('@/assets/images/insert-code.png')"
        alt="Encontre seu e-mail."
      />
    </div>
    <h5 class="text-center">
      Código de verificação
    </h5>
    <p class="text-login">
      O código de verificação acabou de ser
      enviado para <span class="text-dark">{{ getEmailPasswordRecovery }}</span>
    </p>
    <b-form-group class="m-2">
      <validation-observer ref="auth">
        <validation-provider
          v-slot="{ errors }"
          name="Código"
          rules="required"
        >
          <b-form-group>
            <label
              class="label-login"
              for="login"
            >Inserir código</label>
            <b-input-group>
              <b-form-input
                id="code"
                v-model="form.password"
                :state="errors.length > 0 ? false : null"
                type="text"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
      <b-button
        block
        class="mt-4 font-weight-bolder btn-grad"
        @click.prevent="validationForm()"
      >Continuar</b-button>
      <line-divider />
      <b-button
        block
        class="mt-3 font-weight-bolder"
        variant="outline-primary"
        @click="alternateComponentAuth('RecoveryContent')"
      >Enviar código novamente</b-button>
    </b-form-group>
  </div>
</template>
<script>
import {
  BButton, BFormGroup, BFormInput, BInputGroup, BImgLazy,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import LineDivider from '@/components/line-divider/LineDivider.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BImgLazy,
    LineDivider,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    }
  },
  computed: {
    ...mapGetters({ getEmailPasswordRecovery: 'auth/getEmailRecoveryPassword' }),
  },
  methods: {
    ...mapMutations({ alternateComponentAuth: 'auth/SET_COMPONENT_PAGE' }),
    validationForm() {
      this.$refs.auth.validate().then(success => {
        this.form.email = this.getEmailPasswordRecovery

        if (success) {
          useJwt.sendRecoveryCode(this.form).then(res => {
            useJwt.setRecoveryToken(res.data.access_token)
            useJwt.setUserData(res.data.user)
            useJwt.setDatabase(res.data.dbname)
            this.toast({
              title: 'Código verificado!',
              text: 'Crie sua nova senha para acessar o VerdedSys.',
              variant: 'success',
            })
            this.alternateComponentAuth('NewPasswordContent')
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pages/_auth.scss';
</style>
