<template>
  <div id="password-recovery">
    <div class="d-flex justify-content-center m-5">
      <b-img-lazy
        :src="require('@/assets/images/search-email.png')"
        alt="Encontre seu e-mail."
      />
    </div>
    <h5 class="text-center">
      Encontre seu e-mail
    </h5>
    <p class="text-login">
      Por favor digite seu e-mail de recuperação.
    </p>
    <b-form-group class="m-2">
      <validation-observer ref="auth">
        <validation-provider
          v-slot="{ errors, classes }"
          name="e-mail"
          rules="required|email"
        >
          <b-form-group>
            <label
              class="label-login"
              for="login"
            >E-mail</label>
            <b-input-group>
              <b-form-input
                id="login"
                v-model="form.email"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                type="email"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
      <b-button
        block
        class="mt-4 font-weight-bolder btn-grad"
        @click.prevent="validationForm()"
      >Continuar</b-button>
      <line-divider />
      <b-button
        block
        class="mt-3 font-weight-bolder"
        variant="outline-primary"
        @click="alternateComponentAuth('LoginContent')"
      >Voltar</b-button>
    </b-form-group>
  </div>
</template>
<script>
import {
  BButton, BFormGroup, BFormInput, BInputGroup, BImgLazy,
} from 'bootstrap-vue'
import { mapActions, mapMutations } from 'vuex'
import LineDivider from '@/components/line-divider/LineDivider.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BImgLazy,
    LineDivider,
  },
  data() {
    return {
      form: {
        email: '',
      },
    }
  },
  methods: {
    ...mapMutations({ alternateComponentAuth: 'auth/SET_COMPONENT_PAGE' }),
    ...mapActions({ setEmailRecoveryPassword: 'auth/setEmailRecoveryPassword' }),
    validationForm() {
      this.$refs.auth.validate().then(success => {
        if (success) {
          useJwt.redefinePassword(this.form.email).then(() => {
            this.setEmailRecoveryPassword(this.form.email).then(() => {
              this.toast({
                title: 'E-mail enviado!',
                text: 'Enviamos um código para sua caixa de e-mail, preencha o codigo para criar sua nova senha.',
                variant: 'success',
              })
              this.alternateComponentAuth('InsertCodeContent')
            })
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pages/_auth.scss';
</style>
