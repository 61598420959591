<template>
  <div id="password-recovery">
    <div class="d-flex justify-content-center m-5">
      <b-img-lazy
        :src="require('@/assets/images/insert-code.png')"
        alt="Encontre seu e-mail."
      />
    </div>
    <h5 class="text-center">
      Crie uma senha nova
    </h5>
    <p class="text-login">
      Por favor digite uma nova senha para ter acesso a sua conta VerdeSys.
    </p>
    <b-form-group class="m-2">
      <validation-observer ref="auth">
        <validation-provider
          v-slot="{ errors }"
          name="senha"
          rules="required|min:6|max:15|password"
        >
          <b-form-group>
            <label
              class="label-login"
              for="login"
            >Nova senha</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="new-password"
                ref="password"
                v-model="newPassword"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType"
              />
              <b-input-group-append
                is-text
                @click="togglePasswordVisibility()"
              >
                <VSIcons
                  :name="passwordToggleIcon"
                  color="#A0AEB9"
                  class="cursor-pointer"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="confirmação"
          rules="required|validate-password:@senha"
        >
          <b-form-group>
            <label
              class="label-login"
              for="login"
            >Confirmar nova senha</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="confirm-new-password"
                v-model="ConfirmNewPassword"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType2"
              />
              <b-input-group-append
                is-text
                @click="togglePasswordVisibility2()"
              >
                <VSIcons
                  :name="passwordToggleIcon2"
                  color="#A0AEB9"
                  class="cursor-pointer"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
      <b-button
        block
        class="mt-4 font-weight-bolder btn-grad"
        @click.prevent="validationForm()"
      >Continuar</b-button>
    </b-form-group>
  </div>
</template>
<script>
import {
  BButton, BFormGroup, BFormInput, BInputGroup, BImgLazy, BInputGroupAppend,
} from 'bootstrap-vue'
import { mapMutations } from 'vuex'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton, BFormGroup, BFormInput, BInputGroup, BImgLazy, BInputGroupAppend, VSIcons,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      newPassword: '',
      confirmNewPassword: '',
      passwordFieldType2: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'Eye' : 'EyeSlash'
    },
    passwordToggleIcon2() {
      return this.passwordFieldType2 === 'password' ? 'Eye' : 'EyeSlash'
    },
  },
  methods: {
    ...mapMutations({ alternateComponentAuth: 'auth/SET_COMPONENT_PAGE' }),
    validationForm() {
      this.$refs.auth.validate().then(success => {
        if (success) {
          const user = {
            id: JSON.parse(localStorage.getItem('user')).id,
            password: this.newPassword,
          }
          useJwt.setNewPasswordRecovery(user).then(() => {
            useJwt.logout()
            this.toast({
              title: 'Senha Alterada!',
              text: 'Sua senha foi alterada, você já pode fazer o login no VerdeSys.',
              variant: 'success',
            })
            this.alternateComponentAuth('LoginContent')
          })
        }
      })
    },
    togglePasswordVisibility2() {
      this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password'
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pages/_auth.scss';
</style>
