<template>
  <div>
    <div class="d-flex justify-content-center">
      <VSIcons
        name="LogoVerdeSys"
        color-primary="#1BAD82"
        color-secondary="#076E5F"
        :width="widthLogo"
        :height="heigthLogo"
      />
    </div>
    <h5 class="text-welcome text-center font-weight-bold">
      Bem vindo de volta
    </h5>
    <p class="text-login">
      Insira suas credenciais para acessar sua conta.
    </p>
    <b-form-group class="m-2">
      <validation-observer ref="auth">
        <validation-provider
          v-slot="{ errors }"
          name="e-mail"
          rules="required|email"
        >
          <b-form-group>
            <label
              class="label-login"
              for="login"
            >E-mail</label>
            <b-input-group>
              <b-form-input
                id="login"
                v-model="form.email"
                placeholder="E-mail"
                :state="errors.length > 0 ? false : null"
                type="email"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="Senha"
          rules="required|min:4"
        >
          <b-form-group>
            <label
              class="label-login"
              for="password"
            >Senha</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="password"
                v-model="form.password"
                placeholder="Senha"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType"
              />
              <b-input-group-append
                is-text
                @click="togglePasswordVisibility()"
              >
                <VSIcons
                  :name="passwordToggleIcon"
                  color="#A0AEB9"
                  class="cursor-pointer"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
      <b-button
        block
        class="mt-2 font-weight-bolder btn-grad"
        :disabled="apiInCall"
        @click.prevent="validationForm()"
      >
        <b-spinner
          v-if="apiInCall"
          small
          class="mr-1"
        /> Entrar
      </b-button>
      <LineDivider />
      <b-button
        block
        class="mt-2 font-weight-bolder"
        variant="outline-primary"
        @click="alternateComponentAuth('RecoveryContent')"
      >Recuperar minha conta</b-button>
    </b-form-group>
  </div>
</template>

<script>
import {
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import { required, email, min } from '@core/utils/validations/validations'
import { mapMutations, mapGetters } from 'vuex'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import LineDivider from '@/components/line-divider/LineDivider.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,

    VSIcons,
    LineDivider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      min,
      form: {
        email: '',
        password: '',
      },
      heigthLogo: '150',
      widthLogo: '300',
    }
  },
  computed: {
    ...mapGetters({ windowSize: 'app/getWindowSize', apiInCall: 'app/getApiInCall', permissions: 'auth/getUserPermissionsAcl' }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'Eye' : 'EyeSlash'
    },
  },
  watch: {
    windowSize() {
      if (this.windowSize > 574) {
        this.heigthLogo = '150'
        this.widthLogo = '300'
      } else if (this.windowSize < 574) {
        this.heigthLogo = '150'
        this.widthLogo = '250'
      }
    },
  },
  created() {
    if (this.windowSize > 574) {
      this.heigthLogo = '150'
      this.widthLogo = '300'
    } else if (this.windowSize < 574) {
      this.heigthLogo = '150'
      this.widthLogo = '250'
    }
  },
  methods: {
    ...mapMutations({ alternateComponentAuth: 'auth/SET_COMPONENT_PAGE', setUserData: 'auth/SET_USER_DATA', setPermissions: 'auth/SET_PERMISSIONS' }),
    validationForm() {
      this.$refs.auth.validate().then(success => {
        if (success) {
          useJwt.login(this.form).then(res => {
            const userData = res.data.user
            this.setUserData(userData)
            this.setPermissions(userData.permission)
            // delete userData.permission
            useJwt.setUserData(userData)
            useJwt.setDatabase(res.data.dbname)
            useJwt.setToken(res.data.access_token)
            useJwt.setIdMyAccount(res.data.accountId)
            this.$ability.update(this.permissions)
            setTimeout(() => {
              if (userData.permission.isActive === false) {
                this.$router.push({ name: 'my-account' })
              } else {
                this.$router.push({ name: 'home' })
              }
            }, 300)
          }).catch(err => {
            if (err.response.status === 401) {
              this.toast({
                title: 'Dados Incorretos',
                text: 'Verifique se seu login e senha estão corretos e tente novamente.',
                variant: 'warning',
              })
            }
            if (err.response.status === 403) {
              this.toast({
                title: 'Conta Inativa',
                text: err.response.data.message,
                variant: 'warning',
              })
            }
          })
        } else {
          this.toast({
            title: 'Erro',
            text: 'Os campos de e-mail e senha devem estar preenchidos corretamente.',
            variant: 'erro',
          })
        }
      })
    },

  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pages/_auth.scss';
</style>
