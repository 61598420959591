<template>
  <div class="auth-page">
    <div class="auth-side">
      <b-img
        :src="require('@/assets/images/login.jpg')"
        fluid-grow
        alt="Imagem logo VerdeSys"
        class="h-100 auth-img"
      />
    </div>
    <div class="content-side">
      <b-container class="auth-inner">
        <component
          :is="contentAuth"
        />
      </b-container>

      <div class="d-flex justify-content-center">
        <span class="footer-auth text-nowrap">©2021 VerdeSys. Todos os direitos reservados </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import LoginContent from './LoginContent.vue'
import RecoveryContent from './RecoveryContent.vue'
import InsertCodeContent from './InsertCodeContent.vue'
import NewPasswordContent from './NewPasswordContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    LoginContent,
    RecoveryContent,
    InsertCodeContent,
    NewPasswordContent,
  },
  computed: {
    ...mapGetters({ windowSize: 'app/getWindowSize', contentAuth: 'auth/getComponentPage' }),
  },
  mounted() {
    localStorage.clear()
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pages/_auth.scss';
.auth-img {
  object-fit: cover;
}
</style>
